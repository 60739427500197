// src/actions/leaveMatch.js
import axios from 'axios';
import { getSessionData, removeSessionData } from '../utils/session';

const leaveMatch = async (apiurl, matchId, sessionId) => {
    const startTime = getSessionData('startTime');
    const leaveTime = new Date().toISOString(); // ISO format time string

    try {
        await axios.put(`${apiurl}/match-stats/leave-match/${matchId}`, {
            sessionId,
            startTime,
            leaveTime,
        });
        removeSessionData('startTime'); // Cleanup session storage
       
        
    } catch (error) {
       // Check if the error response exists (server responded with an error status)
        if (error.response) {
            // Specific handling for 400 Bad Request errors
            if (error.response.status === 400) {
            console.error('Bad Request:', error.response.data.message);
            // Optionally alert or update the UI with the error message
            alert('Bad Request: ' + error.response.data.message);
            } else {
            // Handle other HTTP error statuses
            console.error('Error leaving match:', error.response.data.message || error.response.statusText);
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received from the server:', error.request);
        } else {
            // Something else happened while setting up the request
            console.error('Error leaving match:', error.message);
        }
    }
};

export default leaveMatch;
